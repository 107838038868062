<template>
	<div class="login-screen">
		<!-- Header -->
		<div class="screen-header text-font">
			<div class="header-body text-center">
				<b-row class="justify-content-center">
					<div>
						<img src="../../../../client/src/assets/Logo/ComodeIcon.svg" class="logo" />
						<div class="header-text">共創を愉しみ</div>
						<div class="header-text">クリエイティブを体感しよう。</div>
					</div>
				</b-row>
			</div>
		</div>
		<!-- Page content -->
		<div class="screen-content justify-content-center">
			<validation-observer v-slot="{ handleSubmit }" ref="formValidator">
				<b-form class="form-input" role="form" @submit.prevent="handleSubmit(onSend)">
					<input
						class="login-input"
						alternative
						name="ユーザ名"
						:rules="true"
						placeholder="ログインIDまたはメールアドレス"
						v-model="model.username"
					/>

					<input
						class="login-input"
						alternative
						name="パスワード"
						:rules="true"
						type="password"
						placeholder="パスワード"
						v-model="model.password"
					/>

					<div class="text-center">
						<button native-type="submit" class="submit-button">
							<img :src="require('@/assets/Icon/LoginBtn.svg')" alt="" />
						</button>
						<span :class="{ show: errorMessage.length }" class="invalid-feedback">{{
							errorMessage
						}}</span>
					</div>
				</b-form>
			</validation-observer>
		</div>

		<div class="screen-footer">
			<router-link to="/adminApp/resetpwd" class="text-light"
				><div class="footer-text reset-password">
					パスワードをお忘れですか？
				</div></router-link
			>
			<div class="footer-text">アカウントをお持ちではありませんか？</div>
			<router-link to="/adminApp/register" class="text-light">
				<div class="footer-text footer-text--color">アカウントを作成</div></router-link
			>
		</div>
	</div>
</template>
<script>
import UserService from '../../services/user.service.js';
import { mapState } from 'vuex';
import { extend } from 'vee-validate';
export default {
	data() {
		return {
			model: {
				username: '',
				password: '',
			},
			errorMessage: '',
		};
	},
	computed: {
		// Get projectId from store
		...mapState(['isAuthenticated', 'userInfo', 'specificRoute']),
	},
	methods: {
		async onSend() {
			// this will be called only after form is valid. You can do api call here to login
			this.errorMessage = '';
			let [loginResult, error] = await await UserService.login(
				this.model.username.trim(),
				this.model.password.trim()
			);
			if (error) {
				this.errorMessage = error.message;
			} else {
				console.log({ data: loginResult });
				this.getUserInfo();
			}
		},
		async getUserInfo() {
			let [userInfo, error] = await await UserService.getInfo();
			if (error) {
				console.log(error);
			} else {
				this.$store.commit('setIsAuthenticated', true);
				this.$store.commit('setUserInfo', userInfo);
				console.log('userInfo', { info: this.userInfo });
				this.redirect();
			}
		},
		redirect() {
			if (this.userInfo.authority == 3) {
				if (this.$route.query.pathToMyPage != undefined) {
					this.$router.push({
						path: this.$route.query.pathToMyPage,
					});
					return;
				}
			}
			console.log('redirect', this.userInfo);
			if (this.specificRoute.fullPath) this.specificPathRedirect();
			else this.defaultRedirect();
		},

		defaultRedirect() {
			if (this.userInfo.authority == 1) {
				this.$router.push({
					path: `creatormenu/${this.userInfo.id}/${this.userInfo.authority}`,
				});
			} else if (this.userInfo.authority == 2) {
				this.$router.push({
					path: `adminmenu/${this.userInfo.id}/${this.userInfo.authority}`,
				});
			} else if (this.userInfo.authority == 3) {
				this.$router.push({
					path: `clientmenu/${this.userInfo.id}`,
				});
			}
		},

		specificPathRedirect() {
			let specificUserId = this.specificRoute.params.userId;
			let specificAuthority = this.specificRoute.params.authId;

			if (
				specificUserId == this.userInfo.id &&
				specificAuthority == this.userInfo.authority
			) {
				this.$router.push({
					path: this.specificRoute.fullPath,
				});
			} else this.defaultRedirect();
		},
	},
};
</script>
<style lang="scss" scoped>
$InputBackground: #f7f8f8;
$InputFocusBackground: #eff0f0;
$TextColor: #cccccc;

.login-screen {
	height: 100vh;
	display: grid;
	place-content: center;

	.screen-header {
		margin-bottom: 30px;
		.logo {
			height: 32px;
			width: 171px;
			margin-bottom: 25px;
		}
		.header-text {
			color: #695f4d;
			font-size: 18px;
		}
	}

	.screen-content {
		width: 345px;
		min-height: 186px;
		background-color: white;
		border-radius: 20px;
		padding: 25px;
		.form-input {
			height: 100%;
			display: flex;
			flex-direction: column;
			.login-input {
				width: 100%;
				height: 32px;
				background-color: $InputBackground;
				color: #4d4d4d;
				border: none;
				border-radius: 7px;
				margin-bottom: 12px;
				font-size: 12px;
				padding-left: 10px;
				&::-webkit-input-placeholder {
					font-family: NotoSansJPBold;
					margin-left: 5px;
					color: $TextColor;
				}
				&::-moz-placeholder {
					font-family: NotoSansJPBold;
					color: $TextColor;
				}
				&:focus {
					outline: none;
					background-color: $InputFocusBackground;
				}
				&:-webkit-autofill {
					border: none;
				}
				&:autofill {
					border: none;
				}
			}
			.text-center {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
				flex-grow: 1;
				.submit-button {
					padding: 0;
					border: none;
					background: none;
					img {
						width: 116px;
						height: 27px;
					}
				}
			}
		}
	}

	.screen-footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		.footer-text {
			font-size: 12px;
			color: $TextColor;
		}
		.reset-password {
			margin-top: 12px;
			margin-bottom: 20px;
			text-decoration: underline;
			&:hover {
				color: #b4b2b2;
			}
		}
		.footer-text--color {
			color: #cba76e;
			&:hover {
				color: #c78a28;
			}
		}
	}
}

.text-font {
	font-family: NotoSansJPBold;
}
.show {
	display: block !important;
	text-align: left;
	height: unset !important;
}
</style>
